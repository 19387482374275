import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { ics } from 'calendar-link'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import getFormattedDate from '../../utils/getFormattedDate'
import getTimeRemaining from '../../utils/getTimeRemaining'

function minTwoDigits(n) {
  return (n < 10 ? `0` : ``) + n
}

const StyledSection = styled.div`
  .s-herosection__description {
    br {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  .postblock__link {
    span {
      &.postblock__linktitle {
        display: inline-flex;
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.7);
          transition: transform 0.3s ease;

          transform-origin: bottom right;
          transform: scaleX(0);
        }
      }

      &.postblock__linkarrow {
        transition: all 0.3s;
      }

      &.postblock__linkplus {
        position: relative;
        top: 2px;
        transition: all 1s;
      }
    }

    &:hover {
      span {
        &.postblock__linktitle {
          &::before {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }

        &.postblock__linkarrow {
          transform: translateX(6px);
        }

        &.postblock__linkplus {
          transform: rotate(360deg);
        }
      }
    }
  }
`

function HeroSection({
  title,
  backgroundImage,
  description,
  marketingCycleButtons,
  salesCycleButtons,
  cycle,
  liveAuctionLink,
  auctionLive,
}) {
  const {
    allAuctionJson: { nodes: auctions },
  } = useStaticQuery(graphql`
    query HeroSectionQuery {
      allAuctionJson {
        nodes {
          AuctionDate
        }
      }
    }
  `)
  const [remDays, setRemDays] = useState(0)
  const [remHours, setRemHours] = useState(0)
  const [remMinutes, setRemMinutes] = useState(0)

  useEffect(() => {
    const remaining = getTimeRemaining(auctions[0].AuctionDate)
    setRemDays(remaining.days)
    setRemHours(remaining.hours)
    setRemMinutes(remaining.minutes)
  }, [auctions])

  return (
    <>
      <div className="min-h-screen relative overflow-hidden flex flex-col justify-end text-white">
        <div className="inset-0 absolute md:fixed">
          <Img
            alt="Serction Highlight"
            fluid={backgroundImage.localFile.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            className="w-full h-full absolute inset-0"
          />
          <div className="bg-black opacity-65 absolute inset-0" style={{ opacity: '0.4' }} />
        </div>
        <StyledSection className="w-full relative z-10 pb-35 lg:pb-45 3xl:pb-55 4xl:pb-65 pt-140 sm:pt-190 md:pt-210">
          <div className="container">
            <h1
              className="text-32 md:text-37 lg:text-46 xl:text-64 font-bold leading-none"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="text-20 md:text-24 lg:text-27 xl:text-32 2xl:text-46 font-normal leading-pretight pt-10 s-herosection__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <div className="pt-30 md:pt-40 lg:pt-65 pb-50 md:pb-90 lg:pb-150 flex flex-wrap -mx-5">
              {cycle === `Marketing` &&
                marketingCycleButtons &&
                marketingCycleButtons.map((item) => (
                  <div className="w-full px-5 sm:w-1/2 lg:w-1/4 mb-10" key={item.button.url}>
                    <Link
                      to={item.button.url}
                      className={
                        'block h-full w-full text-white pl-10 pr-20 xl:px-20 pt-9 pb-7 leading-tight text-17 font-light postblock__link ' +
                        (item.button.url === '/timed-auctions/' ? 'bg-red' : 'bg-red')
                      }
                    >
                      <span
                        className="font-bold text-18 xl:text-19"
                        dangerouslySetInnerHTML={{ __html: item.button.title }}
                      />
                      <span className="flex justify-between items-center">
                        <span
                          className="postblock__linktitle text-15 xl:text-16"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <span className="font-bold pb-2 postblock__linkarrow">→</span>
                      </span>
                    </Link>
                  </div>
                ))}
              {cycle === `Sales` &&
                salesCycleButtons &&
                salesCycleButtons.map((item) => (
                  <div className="w-full px-5 sm:w-1/2 lg:w-1/4 mb-10" key={item.button.url}>
                    <Link
                      to={item.button.url}
                      className={
                        'block h-full w-full text-white pl-10 pr-20 xl:px-20 pt-9 pb-7 leading-tight text-17 font-light postblock__link bg-red'
                      }
                    >
                      <span
                        className="font-bold text-18 xl:text-19"
                        dangerouslySetInnerHTML={{ __html: item.button.title }}
                      />
                      <span className="flex justify-between items-center">
                        <span
                          className="postblock__linktitle text-15 xl:text-16"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <span className="font-bold pb-2 postblock__linkarrow">→</span>
                      </span>
                    </Link>
                  </div>
                ))}
            </div>

            <div className="flex flex-wrap justify-center md:justify-start text-center md:text-left">
              <div className="pb-40 md:pb-0 md:border-r-3 border-white px-20 md:pl-20 md:pr-30 lg:pr-50">
                {auctionLive ?
                  <a href={liveAuctionLink} target="_blank" rel="noreferrer">
                    <h2 className="text-30 md:text-32 lg:text-40 font-bold leading-none flex items-center">
                      <span>
                        <img src="/images/liveauction.png" alt="Live" width="40" height="29" />
                      </span>
                      <span className="ml-15">Live Auction</span>
                    </h2>
                  </a>
                : <h2 className="text-30 md:text-32 lg:text-40 font-bold leading-none">
                    Next Auction
                  </h2>
                }
                <div className="text-22 md:text-24 lg:text-30 xl:text-36 leading-none pt-30 lg:pt-23">
                  15<sup>th</sup> October 2024
                </div>
                {/*  <div */}
                {/*   className="text-22 md:text-24 lg:text-30 xl:text-36 leading-none pt-30 lg:pt-23" */}
                {/*   dangerouslySetInnerHTML={{ */}
                {/*      __html: getFormattedDate(auctions[0].AuctionDate), */}
                {/*    }} */}
                {/* ></div> */}
                {/* <div>11th Sep - lots 1-150 | 12th Sep - lots 151 onwards</div> */}
                <div className="text-18 md:text-20 lg:text-24 xl:text-27">
                  Auction starts at 9:30AM
                </div>
                {!auctionLive ?
                  <a
                    href={ics({
                      title: `AHL Auction`,
                      start: auctions[0].AuctionDate,
                      end: auctions[0].AuctionDate,
                    })}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white inline-flex leading-normal items-center postblock__link font-bold text-19"
                  >
                    <span className="block text-16 md:text-18 lg:text-20 mr-10 mb-3 postblock__linkplus">
                      +
                    </span>
                    <span className="block postblock__linktitle">Add to Calendar</span>
                  </a>
                : null}
              </div>
              <div className="pb-40 md:pb-0 md:flex-grow px-20 md:pr-0 md:pl-30 lg:pl-45">
                <div className="text-16 md:text-18 lg:text-21 font-light leading-snug">
                  <strong className="font-bold text-red">Hosted via Livestream</strong>
                  <br />
                  This auction will be
                  <br />
                  remote bidding only
                  {/* will open at 6:30PM
                <br />
                on Friday 26th February 2021 */}
                </div>
                {/* {auctionLive ? ( */}
                <Link
                  to="/guide-to-buying-at-auction/"
                  className="ml-30 md:ml-0 inline-flex items-center postblock__link font-bold text-16 lg:text-18 xl:text-19 mt-11"
                >
                  <span className="block postblock__linktitle">Guide to Buying at Auction</span>
                  <span className="block text-16 md:text-18 lg:text-20 ml-15 mb-3 postblock__linkarrow">
                    →
                  </span>
                </Link>
                {/* ) : (
                <Link
                  to="/register-to-bid/"
                  className="ml-30 md:ml-0 inline-flex items-center postblock__link font-bold text-16 lg:text-18 xl:text-19 mt-11"
                >
                  <span className="block postblock__linktitle">
                    Remote Bidding
                  </span>
                  <span className="block text-16 md:text-18 lg:text-20 ml-15 mb-3 postblock__linkarrow">
                    →
                  </span>
                </Link>
              )} */}
              </div>
              {auctionLive ?
                <div className="md:border-l-3 border-white px-20 md:pr-0 md:pl-30 lg:pl-45 pt-15">
                  <a
                    className="flex items-center"
                    href={liveAuctionLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <img src="/images/playauction.png" alt="Auction" width="71" height="87" />
                    </span>
                    {/* <h2 className="pl-30 text-24 md:text-28 lg:text-30 font-normal uppercase leading-tight">
                    Watch
                    <br />
                    Live
                    <br />
                    Now
                  </h2> */}
                    <h2 className="pl-30 text-24 md:text-28 lg:text-30 font-normal uppercase leading-tight">
                      Live
                      <br />
                      From
                      <br />
                      9:30AM
                    </h2>
                  </a>
                </div>
              : <div className="md:border-l-3 border-white px-20 md:pr-0 md:pl-30 lg:pl-45">
                  <h2 className="text-22 md:text-24 lg:text-27 xl:text-30 font-bold">
                    We're live in
                  </h2>

                  <div className="flex pt-10">
                    <div className="pr-15 lg:pr-25">
                      <h3 className="text-30 md:text-32 lg:text-40 xl:text-53 font-bold leading-none">
                        {minTwoDigits(remDays)}
                      </h3>
                      <p className="text-14 md:text-16 lg:text-17 xl:text-18 md:font-bold">Days</p>
                    </div>
                    <div className="pr-15 lg:pr-25">
                      <h3 className="text-30 md:text-32 lg:text-40 xl:text-53 font-bold leading-none">
                        {minTwoDigits(remHours)}
                      </h3>
                      <p className="text-14 md:text-16 lg:text-17 xl:text-18 md:font-bold">Hours</p>
                    </div>
                    <div className="md:pr-15 lg:pr-50">
                      <h3 className="text-30 md:text-32 lg:text-40 xl:text-53 font-bold leading-none">
                        {minTwoDigits(remMinutes)}
                      </h3>
                      <p className="text-14 md:text-16 lg:text-17 xl:text-18 md:font-bold">
                        Minutes
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </StyledSection>
      </div>
    </>
  )
}

HeroSection.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.object,
  description: PropTypes.string,
  marketingCycleButtons: PropTypes.array,
  salesCycleButtons: PropTypes.array,
  cycle: PropTypes.string,
  liveAuctionLink: PropTypes.string,
  auctionLive: PropTypes.bool,
}

export default HeroSection
